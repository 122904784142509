<template>
  <el-dialog
      class="dialog" top="0"
      :visible.sync="isVisible"
      :close-on-click-modal="false"
      @update:visible="visible=>$emit('update:visible', visible)"
  >
    <div style="height: 100%;">
      <sheet-container
          ref="sheetContainer"
          :show-contents="false"
          :mode="ViewMode.MODE_VIEW"
          :sheet-list="sheetList"
          :sheet-record-list="sheetRecordList"
          style="height: 100%;"
          :read-only="false"
          :page.sync="currentPage"
          page-by-page
          @input="handleSheetRecordInput">
      </sheet-container>
      <div class="btn-panel__container font-align-right">
        <div class="btn-panel padding layout-vertical">
          <div class="btn-close__container layout-self-right">
            <ys-button round lighting size="small" class="btn-close" @click="handleCloseClick">
              <i class="el-icon-close"></i>
            </ys-button>
          </div>
          <span class="layout-self-center">发送健康报告</span>
          <template v-if="$refs.sheetContainer">
            <div class="margin-top-large font-color-secondary layout-self-center">
              第{{ currentPage + 1 }}/{{ $refs.sheetContainer.totalPages }}页
            </div>
            <ys-button
                class="margin-top-large"
                type="secondary"
                lighting
                size="small"
                @click="handlePreviousPageClick"
                :disabled="$refs.sheetContainer.totalPages <= 1 || currentPage == 0"
            >
            <span>
              <i class="fas fa-caret-left"></i>
              上一页
            </span>
            </ys-button>
            <ys-button
                class="margin-top-large"
                lighting
                type="secondary"
                size="small"
                @click="handleNextPageClick"
                :disabled="$refs.sheetContainer.totalPages <= 1 || currentPage == $refs.sheetContainer.totalPages - 1"
            >
            <span>
              <i class="fas fa-caret-right"></i>
              下一页
            </span>
            </ys-button>
          </template>
          <div class="divider margin-top-large"></div>
          <ys-button class="margin-top-large" lighting @click="handleSubmitClick">
            提交
          </ys-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import SheetContainer from "@/components/sheet/SheetContainer";
import {ViewMode} from "@/assets/javascript/sheet-utils";
import httpapi from "@/assets/javascript/httpapi";
import YsButton from "@/components/wedigets/YsButton";

function doLoadReportByTemplate() {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/report/template/get',
    data: {
      id: this.templateId,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.report = {
      title: res.data.title,
      templateId: res.data.id,
      organizationId: this.organizationId,
      sheetIds: res.data.sheetIds,
      sheetRecordList: res.data.sheetRecordList,
      userId: this.userId,
    }
    this.sheetList = res.data.sheetRecordList.map(x => x.sheet);
    for (let sheetRecord of res.data.sheetRecordList) {
      delete sheetRecord.sheet;
    }
    this.sheetRecordList = res.data.sheetRecordList;
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doLoadReportById() {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/report/get/with_sheet_records',
    data: {
      id: this.reportId,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.report = res.data;
    this.sheetList = res.data.sheetRecordList.map(x => x.sheet);
    for (let sheetRecord of res.data.sheetRecordList) {
      delete sheetRecord.sheet;
    }
    this.sheetRecordList = res.data.sheetRecordList;
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doSendReport() {
  if (this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPostJSON({
    path: `/report/${this.organizationId}/${this.userId}/${encodeURIComponent(this.report.title)}`,
    data: {
      sheetRecordList: this.sheetRecordListInputted,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.report = res.data;
    this.$message.success('发送成功');
    this.isVisible = false;
    this.$emit('update:visible', false);
    this.$emit('confirm', this.report);
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('提交失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doEditReport() {
  if (this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPut({
    path: `/report/${this.report.id}/${encodeURIComponent(this.report.title)}`,
    data: {
      sheetRecordList: this.sheetRecordListInputted,
    }
  }).then((res) => {
    if (loadingCode !== this.loadingCode) return;
    this.report = res.data;
    this.$message.success('发送成功');
    this.isVisible = false;
    this.$emit('update:visible', false);
    this.$emit('confirm', this.report);
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('提交失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

export default {
  name: "ReportInputDialog",
  mixins: [httpapi],
  components: {YsButton, SheetContainer},
  props: {
    visible: Boolean,
    templateId: Number,
    reportId: Number,
    organizationId: Number,
    userId: Number,
  },
  data() {
    return {
      ViewMode,

      isVisible: false,

      loadingCode: 1,
      busyLoadingCode: 0,

      report: null,
      currentPage: 0,
      sheetList: [],
      sheetRecordList: [],

      sheetRecordListInputted: [],
    }
  },
  watch: {
    visible: {
      handler() {
        this.loadingCode++;
        this.report = null;
        this.sheetList = [];
        this.sheetRecordList = [];
        this.sheetRecordListInputted = [];
        this.currentPage = 0;
        this.isVisible = this.visible;
        if (this.reportId) {
          this.loadReportById();
        } else if (this.templateId) {
          this.loadReportByTemplate();
        }
      },
      immediate: true,
    }
  },
  methods: {
    loadReportByTemplate() {
      doLoadReportByTemplate.bind(this)();
    },
    loadReportById() {
      doLoadReportById.bind(this)();
    },
    handlePreviousPageClick() {
      this.$refs.sheetContainer.navigateToPreviousPage();
    },
    handleNextPageClick() {
      this.$refs.sheetContainer.navigateToNextPage();
    },
    handleSubmitClick() {
      let isFinished = this.$refs.sheetContainer.checkBeforeSubmit();
      if (!isFinished) {
        this.$message.warning('请填写必填项');
        return;
      }
      if (this.report.id) {
        doEditReport.bind(this)();
      } else {
        doSendReport.bind(this)();
      }
    },
    handleCloseClick() {
      this.isVisible = false;
      this.$emit('update:visible', false);
    },
    handleSheetRecordInput(evt) {
      this.sheetRecordListInputted = evt;
    }
  }
}
</script>

<style scoped>

.dialog >>> .el-dialog__header {
  visibility: hidden;
  height: 0px;
  margin: 0px;
  padding: 0;
}

.dialog >>> .el-dialog {
  height: 100vh;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding: 0px;
  border-radius: 32px;
  box-sizing: border-box;
  border: 4px solid #f091a6;
  padding: 8px 4px;
  overflow-y: hidden;
  overflow-x: visible;
  width: 430px !important;
  display: block;
}

.dialog >>> .el-dialog__body {
  height: 100vh;
  padding: 0px;
  margin: 0px;
  width: 418px !important;
  overflow: visible;
  box-sizing: border-box;
}

.btn-panel__container {
  height: 0px;
}

.btn-panel {
  border-radius: 16px;
  background-color: white;
  box-sizing: border-box;
  position: fixed;
  min-width: 120px;
  left: calc(50vw + 255px);
  bottom: 100px;
  z-index: 9999;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .3);
  border: 4px solid #f091a6;
}

.btn-close__container {
  width: 0px;
  height: 0px;
}

.btn-close {
  position: relative;
  top: -20px;
  left: -5px;
}

</style>