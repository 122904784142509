<template>
  <div class="report-panel" v-if="report">
    <sheet-container
        ref="sheetContainer"
        :show-contents="false"
        :show-abstract.sync="isShowAbstract"
        :sheet-list="sheetList"
        :sheet-record-list="sheetRecordList"
        mode="view"
        read-only
        style="height: 100%"
    >
      <div slot="footer" class="padding-horizontal footer layout-vertical">
        <div class="layout-horizontal layout-middle margin-bottom" style="height: 48px;">
          <div class="layout-inflexible font-size-medium padding-left">
            <span @click="handleReadCommentClick" class="btn-read-comment">
              <span v-if="report.commentCount > 0">查看点评</span>
              <span v-else><i class="far fa-comment"></i> 点评</span>
              <span v-if="report.commentCount > 0">({{ report.commentCount }})</span>
               <i class="fa fa-angle-right margin-left-small"></i></span>
          </div>
          <div class="layout-flexible"></div>
          <div class="layout-inflexible padding-right">
            <span
                class="btn-comment"
                :class="{
                'font-color-danger': report.commentInfo && report.commentInfo.thumbUpAdded,
                }"
                @click="handleThumbUpClick"
            >
              <i class="far fa-thumbs-up"></i>
              <span
                  v-if="report.commentInfo && report.commentInfo.thumbUpCount > 0"
                  class="btn-comment"
              >
                {{ report.commentInfo.thumbUpCount }}
              </span>
            </span>
          </div>
        </div>
        <div v-for="comment in report.commentList" :key="comment.id">
          <comment :comment="comment" @delete="handleCommentDeleteClick"></comment>
        </div>
        <div class="font-align-center margin-top" v-if="report.commentList.length < report.commentCount">
          <ys-button type="secondary" size="small" @click="handleMoreCommentClick">加载更多点评</ys-button>
        </div>
        <ys-textarea ref="commentInput" v-resize="handleCommentInputResize" placeholder="输入点评" class="margin-top"
                     v-model="commentMessageInputted" @keyup.enter.native="addComment"></ys-textarea>
        <ys-button class="layout-self-right margin-top margin-bottom-large" size="small" type="secondary"
                   @click="addComment">发送
        </ys-button>
      </div>

    </sheet-container>
  </div>
</template>

<script>
import SheetContainer from "@/components/sheet/SheetContainer";
import httpapi from "@/assets/javascript/httpapi";
import TextUtils from "@/assets/javascript/text-utils";
import YsTextarea from "@/components/wedigets/YsTextarea";
import YsButton from "@/components/wedigets/YsButton";
import Comment from "@/components/comment/Comment";

function doLoadCommentInfo() {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/v1/report/comment/info/get',
    data: {
      reportId: this.reportId,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.report.commentCount = res.data.commentCount;
    this.report.commentInfo = res.data;
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doLoadCommentListAfter() {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/v1/report/comment/list/get/after',
    data: {
      reportId: this.reportId,
      id: this.report.commentList.length > 0 ? this.report.commentList[this.report.commentList.length - 1].id : null,
      count: 20,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.$appendAfter(this.report.commentList, res.data);
    this.busyLoadingCode = 0;
    doLoadCommentInfo.bind(this)();
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doAddComment(message) {
  if (this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = 0;
  this.$reqPost({
    path: '/v1/report/comment/add',
    data: {
      reportId: this.reportId,
      organizationId: this.organizationId,
      message,
    }
  }).then(() => {
    if (loadingCode !== this.loadingCode) return;
    if (this.report.commentInfo) {
      this.report.commentInfo.commentCount++;
    }
    this.commentMessageInputted = null;
    this.busyLoadingCode = 0;
    doLoadCommentListAfter.bind(this)();
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doRemoveComment(comment) {
  if (this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = 0;
  this.$reqPost({
    path: '/comment/remove',
    data: {
      id: comment.id,
      commentId: comment.commentId,
    }
  }).then(() => {
    if (loadingCode !== this.loadingCode) return;
    for (let n = this.report.commentList.length - 1; n >= 0; n--) {
      if (this.report.commentList[n].id === comment.id) {
        this.report.commentList.splice(n, 1);
        break;
      }
    }
    if (this.report.commentInfo) {
      this.report.commentInfo.commentCount--;
    }
    this.busyLoadingCode = 0;
    doLoadCommentInfo.bind(this)();
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doLoadReport(id) {
  if(this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/report/get/with_sheet_records',
    data: {
      id,
    }
  }).then(res=>{
    if(loadingCode !== this.loadingCode) return;
    this.report = res.data;
    this.sheetList = res.data.sheetRecordList.map(x=>x.sheet);
    for(let sheetRecord of res.data.sheetRecordList) {
      delete sheetRecord.sheet;
    }
    this.sheetRecordList = res.data.sheetRecordList;
  }).catch(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doAddThumbUp() {
  if (this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = 0;
  console.log('reportId: ' + this.reportId);
  this.$reqPost({
    path: '/v1/report/thumbup/add',
    data: {
      reportId: this.reportId,
    }
  }).then(() => {
    if (loadingCode !== this.loadingCode) return;
    if (this.report.commentInfo) {
      this.report.commentInfo.thumbUpCount++;
      this.report.commentInfo.thumbUpAdded = true;
    }
    this.busyLoadingCode = 0;
    doLoadCommentInfo.bind(this)();
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doRemoveThumbUp() {
  if (this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = 0;
  this.$reqPost({
    path: '/v1/report/thumbup/remove',
    data: {
      reportId: this.reportId,
    }
  }).then(() => {
    if (loadingCode !== this.loadingCode) return;
    if (this.report.commentInfo) {
      this.report.commentInfo.thumbUpCount--;
      this.report.commentInfo.thumbUpAdded = false;
    }
    this.busyLoadingCode = 0;
    doLoadCommentInfo.bind(this)();
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

export default {
  name: "ReportPanel",
  mixins: [httpapi],
  components: {Comment, YsButton, YsTextarea, SheetContainer},
  props: {
    reportId: Number,
    organizationId: Number,
  },
  data() {
    return {
      loadingCode: 1,
      busyLoadingCode: 0,

      report: null,
      sheetList: [],
      sheetRecordList: [],

      isShowAbstract: true,

      commentMessageInputted: null,
    }
  },
  watch: {
    reportId: {
      handler() {
        this.loadingCode ++;
        this.report = null;
        this.sheetList = [];
        this.sheetRecordList = [];
        this.isShowAbstract = true;
        this.commentMessageInputted = null;
        if(this.reportId) {
          this.loadReport();
        }
      },
      immediate: true,
    }
  },
  methods: {
    loadReport() {
      doLoadReport.bind(this)(this.reportId);
    },
    handleReadCommentClick() {
      this.$refs.sheetContainer.scrollToBottom();
    },
    handleThumbUpClick() {
      if (this.report.commentInfo?.thumbUpAdded) {
        doRemoveThumbUp.bind(this)();
      } else {
        doAddThumbUp.bind(this)();
      }
    },
    addComment() {
      if (TextUtils.isBlank(this.commentMessageInputted)) {
        return;
      }
      doAddComment.bind(this)(this.commentMessageInputted);
    },
    handleCommentDeleteClick(comment) {
      doRemoveComment.bind(this)(comment);
    },
    handleMoreCommentClick() {
      doLoadCommentListAfter.bind(this)();
    },
    handleCommentInputResize() {
      if(this.$refs.commentInput.focused) {
        this.$refs.sheetContainer.scrollToBottom();
      }
    }
  }
}
</script>

<style scoped>

.footer {
  min-height: 48px;
  background-color: white;
}

.btn-comment {
  cursor: pointer;
}

.btn-read-comment {
  cursor: pointer;
}

</style>