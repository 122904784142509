<template>
  <div class="report-list-panel layout-horizontal">
    <div class="layout-vertical layout-flexible">
      <div class="title layout-horizontal layout-middle padding layout-inflexible">
        <back-button class="font-size-small" @click="$router.back()"></back-button>
        <span class="font-weight-bold">健康报告管理</span>
      </div>
      <div class="layout-flexible">
        <ys-infinite-scroll-view style="height: 100%">
          <div class="padding">
            <div class="btn-send-report layout-vertical layout-center layout-middle margin-bottom"
              @click="handleSendReportClick">
              <span><i class="far fa-plus"></i> 发送健康报告</span>
            </div>
            <div class="report list-item layout-horizontal layout-middle"
                 :class="{
                    'is-active': currentReport && report.id === currentReport.id,
                 }"
                 v-for="(report) in reportList" :key="report.id"
                 @click="handleReportClick(report)"
            >
              <div class="layout-inflexible">
                <i class="fas fa-file-medical"></i>
              </div>
              <div class="margin-left-large layout-flexible">
                <div class="title font-size-medium">{{ report.title }}</div>
                <div class="font-color-placeholder">发送时间：{{ TimeUtils.formatNormal(report.time) }}</div>
              </div>
              <div class="operations layout-inflexible">
                <ys-button type="secondary" size="small" round lighting
                           @click.stop="handleReportEditClick(report)"
                >
                  <i class="fas fa-pen-alt"></i>
                </ys-button>
                <ys-button type="secondary" size="small" round lighting class="margin-left"
                           @click.stop="handleReportDeleteClick(report)"
                >
                  <i class="far fa-trash-alt font-color-danger"></i>
                </ys-button>
              </div>
            </div>
          </div>
        </ys-infinite-scroll-view>
      </div>
    </div>
    <div style="width: 418px; background-color: #c2e7b0" class="layout-inflexible">
      <report-panel
          ref="reportPanel"
          style="height: 100%;"
          :report-id="currentReport ? currentReport.id : null"
          :organization-id="organizationId"
      ></report-panel>
    </div>
    <report-template-picker-dialog
        :organization-id="organizationId"
        :visible.sync="reportTemplatePickerDialogVisible"
        @pick="handleReportTemplatePicked"
    ></report-template-picker-dialog>
    <report-input-dialog
        :visible.sync="reportInputDialogVisible"
        :report-id="reportPickedId"
        :template-id="reportTemplatePickedId"
        :organization-id="organizationId"
        :user-id="userId"
        @confirm="handleReportInputConfirm"
    ></report-input-dialog>
    <delete-dialog
        :visible.sync="deleteRemindDialogVisible"
        message="您确定要删除该健康报告吗？"
        @confirm="handleReportDeleteConfirm"
    ></delete-dialog>
  </div>
</template>

<script>
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import httpapi from "@/assets/javascript/httpapi";
import {TimeUtils} from "@/assets/javascript/time-utils";
import YsButton from "@/components/wedigets/YsButton";
import BackButton from "@/components/backbutton/BackButton";
import ReportPanel from "@/pages/report/panel/ReportPanel";
import ReportInputDialog from "@/pages/report/dialog/ReportInputDialog";
import ReportTemplatePickerDialog from "@/components/dialog/ReportTemplatePickerDialog";
import DeleteDialog from "@/components/dialog/DeleteDialog";

function doLoadReportList(pageNum) {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/report/list/get/by_organization',
    data: {
      userId: this.userId,
      organizationId: this.organizationId,
      pageNum,
      pageSize: 20,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.$appendAfter(this.reportList, res.data.list);
    this.pageNum = pageNum;
    this.totalPages = res.data.pages;

    if(pageNum == 1 && !this.currentReport && this.$route.query.id) {
      let currentReport =  null;
      for(let report of this.reportList) {
        if(report.id === this.$route.query.id) {
          currentReport = report;
          break;
        }
      }
      if(currentReport) {
        this.currentReport = currentReport;
      } else {
        this.busyLoadingCode = 0;
        this.loadReportById(this.$route.query.id);
      }
    }
  }).catch(() => {
    if (loadingCode != this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode != this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doLoadReportById(id) {
  if(this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.$reqGet({
    path: '/report/get',
    data: {
      id,
    }
  }).then(res=>{
    if(loadingCode !== this.loadingCode) return;
    this.$appendBefore(this.reportList,[res.data]);
    this.currentReport = res.data;
  }).catch(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doDeleteReport(id) {
  if(this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.$reqPost({
    path: '/report/remove',
    data: {
      id,
    }
  }).then(()=>{
    if(loadingCode !== this.loadingCode) return;
    let deleteIndex = -1;
    for(let n = this.reportList.length - 1; n >= 0; n--) {
      if(this.reportList[n].id === id) {
        this.reportList.splice(n, 1);
        deleteIndex = n;
        break;
      }
    }
    if(deleteIndex >= 0) {
      if(deleteIndex >= this.reportList.length) {
        deleteIndex --;
      }
      if(deleteIndex < this.reportList.length && deleteIndex >= 0) {
        this.currentReport = this.reportList[deleteIndex];
      }
    }
    window.eventBus.$emit('update:report');
  }).catch(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

export default {
  name: "ReportListPanel",
  mixins: [httpapi],
  components: {
    DeleteDialog,
    ReportTemplatePickerDialog, ReportInputDialog, ReportPanel, BackButton, YsButton, YsInfiniteScrollView},
  props: {
    userId: Number,
    organizationId: Number,
  },
  data() {
    return {
      TimeUtils,

      loadingCode: 1,
      busyLoadingCode: 0,

      reportList: [],
      pageNum: 1,
      totalPages: 0,

      loadCurrentReport: false,
      currentReport: null,

      reportInputDialogVisible: false,
      reportPickedId: null,
      reportTemplatePickedId: null,

      reportTemplatePickerDialogVisible: false,

      deleteRemindDialogVisible: false,
    }
  },
  computed: {
    inputParams() {
      const {userId, organizationId} = this;
      return {
        userId,
        organizationId,
      }
    }
  },
  watch: {
    inputParams: {
      handler() {
        this.reload();
      },
      immediate: true,
    }
  },
  methods: {
    loadReportList(pageNum = 1) {
      doLoadReportList.bind(this)(pageNum);
    },
    loadMore() {
      if (this.pageNum < this.totalPages) {
        this.loadReportList(this.pageNum + 1);
      }
    },
    loadReportById(id) {
      doLoadReportById.bind(this)(id);
    },
    handleReportClick(report) {
      this.currentReport = report;
    },
    handleSendReportClick() {
      this.reportTemplatePickerDialogVisible = true;
    },
    handleReportEditClick(report) {
      this.currentReport = report;
      this.reportPickedId = report.id;
      this.reportTemplatePickedId = null;
      this.reportInputDialogVisible = true;
    },
    handleReportTemplatePicked(reportTemplateList) {
      this.reportTemplatePickedId = reportTemplateList[0].id;
      this.reportPickedId = null;
      this.reportInputDialogVisible = true;
    },
    handleReportInputConfirm(id) {
      this.loadReportById(id);
      this.$refs.reportPanel.loadReport();
      window.eventBus.$emit('update:report');
    },
    handleReportDeleteClick(report) {
      this.reportPickedId = report.id;
      this.deleteRemindDialogVisible = true;
    },
    handleReportDeleteConfirm() {
      let reportId = this.reportPickedId;
      if(reportId) {
        doDeleteReport.bind(this)(reportId);
      }
    },
    reload() {
      this.loadingCode++;
      this.reportList = [];
      this.pageNum = 1;
      this.totalPages = 0;
      this.currentReport = null;
      this.loadCurrentReport = true;
      if (this.userId && this.organizationId) {
        this.loadReportList();
      }
    }
  },
  activated() {
    this.reload();
  }
}
</script>

<style scoped>

.report-list-panel {
  height: inherit;
}

.report.is-active .title {
  font-weight: bold;
  color: #1f1f1f;
}

.btn-send-report {
  height: 100px;
  width: 200px;
  border-radius: 8px;
  border: 1px solid #dadada;
  cursor: pointer;
  user-select: none;
  background-color: white;
}

.btn-send-report:hover {
  box-shadow: 0 0 4px #bec0c0;
}

.list-item {
  width: auto;
}

.list-item .operations {
  visibility: hidden;
}

.list-item:hover .operations {
  visibility: visible;
}
</style>